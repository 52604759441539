import React, { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { ChatState } from "../../context/ChatProvider";
import axios from "axios";
import SingleChatList from "./SingleChatList";
import ChatLoading from "../ChatLoading";
import { useNavigate } from "react-router-dom";

const LeftChatList = ({ fetchAgain, setFetchAgain }) => {
  const navigate = useNavigate();
  const { chats, setChats, user } = ChatState();
  const [loading, setLoading] = useState(false);
  const [isLoadingChat, setIsLoadingChat] = useState(false);
  const config = {
    headers: {
      authorization: `Bearer ${user.token}`,
    },
  };
  const fetchChats = (showLoading = true) => {
    if (showLoading) setLoading(true);
    axios
      .get(`/api/chat`, config)
      .then((response) => {
        setLoading(false);
        setChats(response.data);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          // navigate("/not-authorize");
        } else {
          console.log(e.message);
        }
      });
  };

  useEffect(() => {
    fetchChats(true);
  }, []);

  useEffect(() => {
    setIsLoadingChat(!isLoadingChat);
    fetchChats(false);
  }, [fetchAgain]);
  return (
    <>
      <div className="search-bar">
        <input className="form-control" placeholder="Search something" />
      </div>
      <div className="chat-list">
        <Scrollbars
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHide
          className="chatlist-scrollbar"
        >
          {loading ? (
            <ChatLoading></ChatLoading>
          ) : (
            chats.length &&
            chats.map((chat, index) => (
              <SingleChatList
                key={index}
                chat={chat}
                isLoadingChat={isLoadingChat}
              ></SingleChatList>
            ))
          )}
        </Scrollbars>
      </div>
    </>
  );
};

export default LeftChatList;
