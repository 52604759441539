import React from "react";
import { Route, Routes } from "react-router-dom";

import "./App.css";
import HomePage from "./page/HomePage";
import ChatPage from "./page/ChatPage";
import NotAuthorize from "./page/NotAuthorize";
const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<HomePage />}></Route>
        <Route exact path="/chat" element={<ChatPage />}></Route>
        <Route exact path="/not-authorize" element={<NotAuthorize />}></Route>
      </Routes>
    </div>
  );
};

export default App;
