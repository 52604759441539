import React from "react";
import Avatar from "react-avatar";
import "../../style/Chat.css";
import "../../style/ChatLeftBar.css";
import { ChatState } from "../../context/ChatProvider";
import { getSenderName, getSenderImage } from "../../config/ChatLogic";

const ChatBoxHeader = () => {
  const { selectedChat } = ChatState();

  return (
    <div className="chat-header-outer">
      <div className="chat-header d-flex justify-content-between align-items-center">
        <div className="chat-header-left-content d-flex">
          <div className="single-chat-user">
            <Avatar
              src={getSenderImage(selectedChat)}
              name={getSenderName(selectedChat)}
              round={true}
              size="50"
            />
            <span className="user-online"></span>
          </div>
          <div className="single-chat-list-content">
            <span>{getSenderName(selectedChat)}</span>
            <p>Active Now</p>
          </div>
        </div>
        <div className="chat-header-right-content d-flex align-items-center">
          <i className="fas fa-phone"></i>
          <i className="fas fa-video"></i>
          <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  );
};

export default ChatBoxHeader;
