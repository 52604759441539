export const isSameSenderMargin = (messages, m, i, userId) => {
  // console.log(i === messages.length - 1);

  if (
    i < messages.length - 1 &&
    messages[i + 1].sender._id === m.sender._id &&
    messages[i].sender._id !== userId
  )
    return 22;
  else if (
    (i < messages.length - 1 &&
      messages[i + 1].sender._id !== m.sender._id &&
      messages[i].sender._id !== userId) ||
    (i === messages.length - 1 && messages[i].sender._id !== userId)
  )
    return 0;
  else return "auto";
};

export const isSameSender = (messages, m, i, userId) => {
  return (
    i < messages.length - 1 &&
    (messages[i + 1].sender._id !== m.sender._id ||
      messages[i + 1].sender._id === undefined) &&
    messages[i].sender._id !== userId
  );
};

export const isLastMessage = (messages, i, userId) => {
  return (
    i === messages.length - 1 &&
    messages[messages.length - 1].sender._id !== userId &&
    messages[messages.length - 1].sender._id
  );
};

export const isSameUser = (messages, m, i) => {
  return i > 0 && messages[i - 1].sender._id === m.sender._id;
};

export const getSender = (loggedUser, users) => {
  return users[0]._id === loggedUser._id ? users[1].name : users[0].name;
};

export const getSenderFull = (loggedUser, users) => {
  return users[0]._id === loggedUser._id ? users[1] : users[0];
};

export const getSenderName = (chat) => {
  if (chat) {
    if (chat.isGroupChat) {
      return chat.chatName;
    } else {
      const loggedUser = JSON.parse(localStorage.getItem("userInfo"));
      return chat.users[0]._id === loggedUser._id
        ? chat.users[1].name
        : chat.users[0].name;
    }
  } else {
    return "";
  }
};

export const getLatestMessage = (chat) => {
  if (chat) {
    if (chat.isGroupChat) {
      return "";
    } else {
      return chat.latestMessage.content;
    }
  } else {
    return "";
  }
};

export const getSenderImage = (chat) => {
  if (chat) {
    if (chat.isGroupChat) {
      return "";
    } else {
      const loggedUser = JSON.parse(localStorage.getItem("userInfo"));
      return chat.users[0]._id === loggedUser._id
        ? chat.users[1].picture
        : chat.users[0].picture;
    }
  } else {
    return "";
  }
};
