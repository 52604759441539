import React, { useState } from "react";
import ChatBoxHeader from "./ChatBoxHeader";
import "../../style/Chat.css";
import "../../style/ChatLeftBar.css";
import ChatBottomBar from "./ChatBottomBar";
import Chat from "./Chat";

const ChatBox = ({ fetchAgain, setFetchAgain }) => {
  const [newMessage, setNewMessage] = useState("");
  return (
    <>
      <ChatBoxHeader
        fetchAgain={fetchAgain}
        setFetchAgain={setFetchAgain}
      ></ChatBoxHeader>
      <Chat
        fetchAgain={fetchAgain}
        setFetchAgain={setFetchAgain}
        newMessage={newMessage}
        setNewMessage={setNewMessage}
      ></Chat>
      <ChatBottomBar
        fetchAgain={fetchAgain}
        setFetchAgain={setFetchAgain}
        setNewMessage={setNewMessage}
      ></ChatBottomBar>
    </>
  );
};

export default ChatBox;
