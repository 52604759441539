import React, { useState } from "react";
import { ChatState } from "../../context/ChatProvider";
import axios from "axios";
import Picker from "emoji-picker-react";

const ChatBottomBar = ({ fetchAgain, setFetchAgain, setNewMessage }) => {
  const { selectedChat, user } = ChatState();
  const [inputMessage, setInputMessage] = useState("");
  const handleSendMessage = async (event) => {
    if ((event.key === "Enter" || event.type === "click") && inputMessage) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        };
        const { data } = await axios.post(
          "api/message",
          {
            content: inputMessage,
            chatId: selectedChat._id,
          },
          config
        );
        setNewMessage(data);
        setInputMessage("");
        setFetchAgain(!fetchAgain);
      } catch (e) {}
    }
  };
  // const onEmojiClick = (event, emojiObject) => {
  //   setChosenEmoji(emojiObject);
  // };
  return (
    <>
      <div className="chatbottombar-area">
        <div className="chatbar-input">
          <input
            type="text"
            className="form-control messageTypingInput"
            placeholder="Type a message"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyPress={(e) => handleSendMessage(e)}
          />
          <button type="submit" onClick={(e) => handleSendMessage(e)}>
            <i className="far fa-paper-plane"></i>
          </button>
        </div>
      </div>
    </>
  );
};

export default ChatBottomBar;
