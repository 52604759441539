import React from "react";

import Login from "../components/Login";
import Signup from "../components/Signup";
import { Heading } from "@chakra-ui/react";

const HomePage = () => {
  return (
    <div>
      <div class="login_container">
        <div className="login_left">
          <Heading size="xl">
            Login to your account <i class="far fa-paper-plane"></i>
          </Heading>
        </div>
        <div className="login_right">
          <Login />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
