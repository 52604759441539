import React from "react";
import Avatar from "react-avatar";
import { ChatState } from "../../context/ChatProvider";
import { getSenderName, getSenderImage } from "../../config/ChatLogic";

const ChatRightContent = () => {
  const { selectedChat } = ChatState();
  return (
    <>
      <div className="chat-right-content">
        <div className="chat-header-left-content d-flex flex-column justify-content-center align-items-center">
          <div className="single-chat-user">
            <Avatar
              src={getSenderImage(selectedChat)}
              name={getSenderName(selectedChat)}
              round={true}
              size="50"
            />
            <span className="user-online"></span>
          </div>
          <div className="single-chat-list-content">
            <span>{getSenderName(selectedChat)}</span>
            <p>Active Now</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatRightContent;
