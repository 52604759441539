import axios from "axios";

export const authVerification = async (useInfo) => {
  let result = false;
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${useInfo.token}`,
      },
    };
    const { data } = await axios.post("/api/verify/token", useInfo, config);
    result = true;
  } catch (error) {}
  return true;
};
