import { Tooltip } from "@chakra-ui/react";
import React from "react";
import Avatar from "react-avatar";
import { ChatState } from "../../context/ChatProvider";
import {
  isSameSender,
  isLastMessage,
  isSameSenderMargin,
} from "../../config/ChatLogic";
import moment from "moment";

const SingleChat = ({ message, index, messages }) => {
  const { user } = ChatState();

  const divClass =
    message.sender._id === user._id
      ? "messgae-box d-flex align-items-end message-sender"
      : "messgae-box d-flex align-items-end";
  return (
    <div className={divClass}>
      <div className="sender-img" style={{ marginBottom: "8px" }}>
        {(isSameSender(messages, message, index, user._id) ||
          isLastMessage(messages, index, user._id)) && (
          <Avatar src="" name="MD Nazim" round={true} size="25" />
        )}
      </div>

      <div
        style={{
          marginLeft: isSameSenderMargin(messages, message, index, user._id),
        }}
        className="sender-text"
      >
        <div className="sender-single-msg">
          <p>{message.content}</p>
          <span> {moment(new Date(message.createdAt)).fromNow()}</span>
        </div>
      </div>
    </div>
  );
};

export default SingleChat;
