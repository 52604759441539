import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authVerification } from "../config/TokenVerify";
const ChatContext = createContext();

const ChatProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [selectedChat, setSelectedChat] = useState();
  const [chats, setChats] = useState([]);
  const [notification, setNotification] = useState([]);

  const verifyToken = async (useInfo) => {
    const result = await authVerification(useInfo);
    if (!result) {
      localStorage.removeItem("userInfo");
      navigate("/");
    } else {
      return;
    }
  };

  useEffect(() => {
    const useInfo = JSON.parse(localStorage.getItem("userInfo"));
    setUser(useInfo);
    const is_verified = verifyToken(useInfo);
    if (is_verified && useInfo) {
      navigate("/chat");
    } else {
      navigate("/");
    }
  }, [navigate]);

  return (
    <ChatContext.Provider
      value={{
        user,
        setUser,
        selectedChat,
        setSelectedChat,
        chats,
        setChats,
        notification,
        setNotification,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
export default ChatProvider;

export const ChatState = () => {
  return useContext(ChatContext);
};
