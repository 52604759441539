import {
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "react-google-login";

const Login = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async () => {
    if (!email || !password) {
      toast({
        title: "Please fill all the fields",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else {
      setLoading(true);
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };

      try {
        axios
          .post("/api/user/login", { email, password }, config)
          .then((response) => {
            toast({
              title: "Login success",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
            localStorage.setItem("userInfo", JSON.stringify(response.data));
            setLoading(false);
            navigate("/chat");
          })
          .catch(function (error) {
            const errorMessage = error.response.data.error;
            toast({
              title: errorMessage,
              status: "error",
              duration: 2000,
              isClosable: true,
            });
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        toast({
          title: error.messgae,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        setLoading(false);
      }
    }
  };

  const googleLogin = (response) => {
    const googleResponse = response.profileObj;
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    axios
      .post(
        "https://react-chat-api.vercel.app/api/user/google-login",
        googleResponse,
        config
      )
      .then((response) => {
        toast({
          title: "Login success",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        localStorage.setItem("userInfo", JSON.stringify(response.data));
        setLoading(false);
        navigate("/chat");
      })
      .catch(function (error) {
        const errorMessage = error.response.data.error;
        toast({
          title: errorMessage,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        setLoading(false);
      });
  };

  const googleLoginError = (error) => {
    console.log(error);
  };

  return (
    <>
      <div className="login_info">
        <Heading size="md">Login to your account</Heading>
      </div>
      <div className="login_input_sec">
        <div className="login_div_section">
          <FormControl isRequired>
            <FormLabel htmlFor="email">Email</FormLabel>
            <Input
              id="email"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
        </div>
        <div className="login_div_section">
          <FormControl isRequired>
            <FormLabel htmlFor="password">Password</FormLabel>
            <Input
              id="password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>
        </div>
      </div>
      <div className="login_btn_sec">
        <Button
          isLoading={loading}
          w="100%"
          colorScheme="blue"
          onClick={handleLogin}
          className="common_btn"
        >
          Login
        </Button>
        <GoogleLogin
          clientId="348033998566-cur5sb4t9rpl2gukhsa13q093gn0bv45.apps.googleusercontent.com"
          render={(renderProps) => (
            <Button
              w="100%"
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              variant="outline"
              className="google_btn"
            >
              Login with Google
            </Button>
          )}
          buttonText="Login"
          onSuccess={googleLogin}
          onFailure={googleLoginError}
          cookiePolicy={"single_host_origin"}
        />
      </div>
    </>
  );
};

export default Login;
