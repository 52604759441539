import React, { useEffect, useState, useRef } from "react";
import Avatar from "react-avatar";
import { Scrollbars } from "react-custom-scrollbars";
import { ChatState } from "../../context/ChatProvider";
import { getSenderName, getSenderImage } from "../../config/ChatLogic";
import axios from "axios";
import SingleChat from "../Chat/SingleChat";
import ChatLoading from "../ChatLoading";

const ChatBox = ({ fetchAgain, setFetchAgain, newMessage, setNewMessage }) => {
  const messagesEndRef = useRef(null);
  const { user, selectedChat } = ChatState();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchchatMessages = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      setLoading(true);
      const { data } = await axios.get(
        `api/message/${selectedChat._id}`,
        config
      );
      setMessages(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchchatMessages();
  }, [selectedChat]);

  useEffect(() => {
    if (newMessage) {
      setMessages([...messages, newMessage]);
    }
    setNewMessage("");
  }, [newMessage]);

  useEffect(() => {
    messagesEndRef.current.scrollIntoView({
      block: "end",
      inline: "nearest",
    });
  });

  return (
    <>
      <div className="main-chat-box">
        <Scrollbars
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHide
          className="mainchat-scrollbar"
        >
          {loading ? (
            <ChatLoading></ChatLoading>
          ) : (
            messages.length &&
            messages.map((message, index) => (
              <SingleChat
                key={index}
                index={index}
                messages={messages}
                message={message}
              ></SingleChat>
            ))
          )}
          <div ref={messagesEndRef} />
        </Scrollbars>
      </div>
    </>
  );
};

export default ChatBox;
