import React from "react";
import Avatar from "react-avatar";
import { MDBBadge, MDBIcon } from "mdb-react-ui-kit";
import {
  getSenderName,
  getLatestMessage,
  getSenderImage,
} from "../../config/ChatLogic";
import { ChatState } from "../../context/ChatProvider";
const SingleChatList = ({ chat }) => {
  const { selectedChat, setSelectedChat } = ChatState();

  return (
    <div
      className={
        selectedChat === chat ? "single-chat-list active" : "single-chat-list"
      }
      onClick={() => setSelectedChat(chat)}
    >
      <div className="single-chat-user">
        <Avatar
          src={getSenderImage(chat)}
          name={getSenderName(chat)}
          round={true}
          size="50"
        ></Avatar>
        <span className="user-online"></span>
      </div>
      <div className="single-chat-list-content">
        <span>{getSenderName(chat)}</span>
        <p>
          {getLatestMessage(chat).length > 30
            ? `${getLatestMessage(chat).substring(0, 30)}...`
            : getLatestMessage(chat)}
        </p>
      </div>
      {/* <div className="singlechat-right-content">
        <MDBIcon fas icon="thumbtack"></MDBIcon>
        <MDBBadge color="success" notification pill>
          10
        </MDBBadge>
      </div> */}
    </div>
  );
};

export default SingleChatList;
