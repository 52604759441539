import React, { useState } from "react";
import { ChatState } from "../context/ChatProvider";
// import Sidebar from "../components/Sidebar";
// import { Box } from "@chakra-ui/react";
// import ChatBox from "../components/ChatBox";
// import MyChats from "../components/MyChats";
import LeftTopBar from "../components/LeftComponents/LeftTopBar";
import LeftChatList from "../components/LeftComponents/LeftChatList";
import ChatContainer from "../components/Chat/ChatContainer";

const ChatPage = () => {
  const [fetchAgain, setFetchAgain] = useState(false);
  const { user } = ChatState();

  return (
    <div className="chat-outer">
      <div className="row g-0">
        <div className="col-3 left-side-bar">
          {user && <LeftTopBar></LeftTopBar>}
          {user && (
            <LeftChatList
              fetchAgain={fetchAgain}
              setFetchAgain={setFetchAgain}
            ></LeftChatList>
          )}
        </div>
        <div className="col-9">
          {user && (
            <ChatContainer
              fetchAgain={fetchAgain}
              setFetchAgain={setFetchAgain}
            ></ChatContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatPage;
