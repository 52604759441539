import React from "react";
import ChatBox from "./ChatBox";
import ChatRightContent from "./ChatRightContent";
import { ChatState } from "../../context/ChatProvider";

const ChatContainer = ({ fetchAgain, setFetchAgain }) => {
  const { selectedChat } = ChatState();
  return (
    <>
      {selectedChat ? (
        <div className="row g-0">
          <div className="col-9">
            <ChatBox
              fetchAgain={fetchAgain}
              setFetchAgain={setFetchAgain}
            ></ChatBox>
          </div>
          <div className="col-3">
            <ChatRightContent></ChatRightContent>
          </div>
        </div>
      ) : (
        <div className="No_chat_selected">
          <h1>
            Let chat with your love <i className="far fa-paper-plane"></i>
          </h1>
        </div>
      )}
    </>
  );
};

export default ChatContainer;
