import React, { useState } from "react";
import "../../style/ChatLeftBar.css";
import { ChatState } from "../../context/ChatProvider";
import Avatar from "react-avatar";

const LeftTopBar = (props) => {
  const [showMenuItem, setShowMenuItem] = useState(false);
  const handleMenuItem = () => {
    setShowMenuItem(!showMenuItem);
  };

  const { user } = ChatState();
  return (
    <div className="sidenav">
      <div className="sidenav-topbar d-flex justify-content-between align-items-center">
        <Avatar
          src={user.picture}
          name={user.name}
          round={true}
          size="35"
        ></Avatar>
        <h4>Chats</h4>
        <div className="sidenav-topbar-icon">
          <a href="">
            <i className="far fa-bell"></i>
            <span className="badge rounded-pill badge-notification bg-danger">
              1
            </span>
          </a>
          <i
            onClick={handleMenuItem}
            className="fa fa-ellipsis-v cursor-pointer menuItemIcon"
            aria-hidden="true"
          ></i>
          {showMenuItem ? (
            <div className="dropdownMenu">
              <ul>
                <li>Setting</li>
                <li>Logout</li>
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default LeftTopBar;
